html,
body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.score-tally {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 40px;
  height: 40px;

  color: white;
  font-size: 3em;
  padding: 30px;
  transition: transform 0.3s ease;
  z-index: 1;
  border-radius: 20px;

  user-select: none;
}

.current-score {
  bottom: 10px;
  left: 10px;
  background-color: slateblue;
}

.highscore {
  bottom: 10px;
  right: 10px;
  background-color: gold;
}

#menu {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

#menu div {
  font-size: 5rem;
  cursor: pointer;
  font-weight: bold;
  user-select: none;
}

.hidden {
  display: none !important;
}

.center-absolute {
  transform: translateX(50vw);
  transform: translateY(50%);
  /* right: 0;
  bottom: 0;
  margin: auto; */
}

@media (min-width: 768px) {
  .score-tally {
    padding: 40px;
  }

  .current-score {
    top: 50px;
    left: 50px;
  }

  .highscore {
    top: 200px;
    left: 50px;
  }
}
